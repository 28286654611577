import React from 'react'
import Layout from '../components/Layout'
import Container from '@components/Container'
// import FourOhFour from '../../../common/components/Error'

const RootIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Container>
        <div></div>
        {/* <FourOhFour configEmail={config.authorDefaultEmail} /> */}
      </Container>
    </Layout>
  )
}

export default RootIndex
